@import 'import';
.root {
  @include font($size: rem(13), $color: $grey-text);

  position: relative;
  z-index: $z-index-f-m-agency;

  display: grid;
  grid-template: 1fr 1fr / auto auto;
  column-gap: 8px;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 60px;
  padding: 0 15px;

  white-space: nowrap;

  background-color: $gray-light-2;
  box-shadow: inset 0 rem(-1) 0 0 $black-006;

  :global(.is-nav-active) & {
    z-index: $z-index-global-navigation--overlapped;
  }

  @include media-breakpoint-up(md) {
    grid-template: 1fr / auto 1fr auto auto;
    height: 33px;
  }
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.aboutTooltip {
  justify-self: flex-end;

  @include media-breakpoint-up(md) {
    justify-self: unset;
  }
}

.email {
  @include font($weight: 500, $color: $black, $decoration: underline);

  display: flex;
  gap: 5px;
  align-items: flex-end;

  &:hover {
    color: $grey-text;
  }

  .icon {
    width: 20px;
    height: 20px;
  }
}

.phone {
  line-height: rem(20);
  color: $black;

  &:hover {
    color: $grey-text;
    text-decoration: unset;
  }
}
