@import 'import';
.section {
  position: relative;
  padding: 0 8px 30px;
}

.icon {
  $size: 76px;

  position: relative;
  top: -0.5 * $size;

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  width: $size;
  height: $size;
  margin: 0 auto calc(28px - #{0.5 * $size});

  background-color: $white;
  border-radius: 50%;
}

.heading,
.subheading {
  max-width: 280px;
  margin: 0 auto;
  text-align: center;
}

.heading {
  @include font($size: rem(22), $line-height: rem(31), $color: $black);

  margin-bottom: 8px;
}

.subheading {
  @include font($size: rem(15), $line-height: rem(24), $color: $grey-text);

  margin-bottom: 40px;
}

.disableBlock {
  @include disabled-block();
}
