@import 'import';
.root {
  padding: 20px;

  font-size: 0.75rem;
  color: $gray-505;
  text-align: center;

  background: $gray-light-2;

  &.inFooter {
    padding: 50px 20px 0;
    color: $white;
    background: $black;

    a {
      color: $white;

      &:hover {
        color: $gray-410;
        text-decoration: none;
      }
    }
  }
}
