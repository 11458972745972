@import 'import';
.additionalInfo {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}

.icon {
  $icon-side: 30px; // +5 margin around of the icon
  $half-icon-side: calc($icon-side / 2); // +5 margin around of the icon

  position: absolute;
  top: -$half-icon-side;
  bottom: auto;
  left: calc(50% - $half-icon-side);

  :global(.Icon) {
    width: $icon-side;
    height: $icon-side;
  }
}

.container {
  background-color: $error-bg;
  border: 2px solid $error;
  border-radius: 4px;
}

.textBlock {
  margin: 40px 32px;

  @include font($size: rem(13), $line-height: rem(20), $color: $black);
}

.title {
  font-weight: bold;
  text-align: center;
}

.description {
  margin-top: 20px;
  text-align: center;

  &::before {
    content: '• ';
  }
}
