@import 'import';
.root {
  display: flex;
  flex-direction: row;

  width: 100%;
  padding: 60px 20px 150px;

  background-color: $black;

  :global(.loading-skeleton) {
    --base-color: #{rgba($white, 0.06)};
    --highlight-color: #{rgba($white, 0.1)};

    display: flex;
    height: inherit;
  }
}

.logo {
  width: 27.5%;
  height: 40px;

  :global(.loading-skeleton) {
    width: 130px;
  }
}

.content {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  column-gap: 2.25%;

  width: 72.5%;
}

.column {
  height: 425px;

  &:nth-child(1) {
    width: 15%;
  }

  &:nth-child(2) {
    width: 15%;
  }

  &:nth-child(3) {
    width: 22%;
  }

  &:nth-child(4) {
    width: 41.25%;
  }

  .item {
    width: 100%;
    height: 12px;
    margin-bottom: 26px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:global(._head) .item {
    &:first-child {
      height: 18px;
      margin-bottom: 18px;
    }
  }

  &:global(._headless) {
    padding-top: 36px;
  }

  &:global(._last) .item {
    &:not(:first-child) {
      margin-top: 56px;
      margin-bottom: 76px;
    }

    &:last-child {
      width: 50%;
      height: 20px;
      margin: 0;
    }
  }
}
