@import 'import';
.root {
  margin: 0 auto;
  padding: 50px 20px 100px;

  :global(span:has(> .loading-skeleton)) {
    display: block;
  }

  :global(.loading-skeleton) {
    --base-color: #{$gray-110};

    display: flex;
    height: inherit;
    border-radius: inherit;
  }

  @include media-breakpoint-up(xl) {
    width: 100%;
    max-width: 1440px;
    padding: 40px 120px 210px;
  }
}

.banner {
  width: 100%;
  height: 236px;
  margin: 0 0 30px;
  border-radius: 10px;
}

.title {
  width: 50%;
  height: 30px;
  margin: 0 auto 40px;
}

.columns {
  @include media-breakpoint-up(xl) {
    display: flex;
    flex-direction: row-reverse;
    column-gap: 2.4%;
  }
}

.left {
  flex: 1 1 auto;

  .block:nth-child(1) {
    height: 210px;
    margin: 0 0 20px;
    border-radius: 10px;

    @include media-breakpoint-up(xl) {
      height: 463px;
    }
  }

  .block:nth-child(2) {
    height: 211px;
    margin: 0 0 16px;
  }

  .block:nth-child(3) {
    height: 250px;
    margin: 0 0 76px;
  }

  .subHead:nth-child(4) {
    width: 50%;
    height: 24px;
    margin: 0 auto 8px;
  }

  .subHead:nth-child(5) {
    width: 30%;
    height: 28px;
    margin: 0 auto 30px;
  }

  .block:nth-child(6) {
    height: 131px;
    margin: 0 0 20px;
  }
}

.right {
  @include media-breakpoint-up(xl) {
    flex: 0 0 auto;
    width: 375px;
  }

  .block:nth-child(1) {
    height: 317px;
    margin: 0 0 30px;
    opacity: 0.3;
    border-radius: 4px;

    :global(.loading-skeleton) {
      --base-color: #{$black-violet};
    }
  }

  .block:nth-child(2) {
    display: none;

    @include media-breakpoint-up(xl) {
      display: block;
      width: 73.6%;
      height: 51px;
      margin: 0 auto;
    }
  }
}
