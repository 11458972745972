@import 'import';
.monthTitle {
  padding: 10px var(--view-more-dates-inline-padding);
  text-transform: uppercase;
  letter-spacing: 0.125rem;
  background: $ghost-white;

  @include font($size: rem(13), $weight: bold, $line-height: rem(20), $color: $grey-text);
}

.cardSeparator {
  margin-inline: var(--view-more-dates-inline-padding);
  border-bottom: 1.33px solid $lightwhite;

  &:last-of-type {
    display: none;
  }
}

.bestRateHeading {
  padding: 10px var(--view-more-dates-inline-padding);
  text-transform: uppercase;
  letter-spacing: 0.125rem;
  background: $best-rate-background;

  @include font($size: rem(13), $weight: 'bold', $line-height: rem(20), $color: $best-rate-text);
}

.averagePriceText {
  padding: 40px var(--view-more-dates-inline-padding) 60px;
  text-align: center;
  background-color: $flyout-background;

  @include font($size: rem(17), $line-height: rem(28), $color: $grey-text);
}
