@import 'import';
.isDesktop {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}

.isMobile {
  @include media-breakpoint-up(md) {
    display: none !important;
  }
}

.isNotMobile {
  @include media-breakpoint-down(xs) {
    display: none !important;
  }
}
