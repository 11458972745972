@import 'import';
.countdown {
  font-family: $font-family-base;

  :global(.Countdown__digit) {
    gap: 2px;
  }

  :global(.Countdown__value) {
    min-width: rem(40);
    height: rem(32);
    margin: 0 rem(2);
    padding: rem(1) rem(2);

    border: 1px solid $red;

    @include font($name: $font-family-headline, $color: $white);
  }

  :global(.Countdown__label) {
    overflow: hidden;

    width: rem(44);

    font-weight: 700;
    color: $gray-207;
    text-indent: rem(1);
    text-overflow: ellipsis;
    text-transform: uppercase;
    letter-spacing: rem(1);
  }
}

.skeleton {
  display: flex;
  flex: 1;

  min-width: 185px;

  line-height: 45px; // use px since it affects skeleton height

  opacity: 0.3;
}
