@import 'import';
.root {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  :global(.loading-skeleton) {
    --base-color: #{$white-E9};
    --highlight-color: #{$gray-light-2};

    display: flex;
    height: 12px;
  }
}

.left,
.right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 30px;

  @include media-breakpoint-up(md) {
    width: 50%;
    height: 32px;
  }
}

.left {
  column-gap: 8px;
  padding: 0 8px 0 15px;

  @include media-breakpoint-up(md) {
    justify-content: flex-start;
  }

  > :nth-child(1) {
    width: 197px;
  }

  > :nth-child(2) {
    width: 22px;
    height: 22px;
    border-radius: 50%;
  }
}

.right {
  column-gap: 11px;
  padding: 0 15px;

  @include media-breakpoint-up(md) {
    justify-content: flex-end;
  }

  > :nth-child(1) {
    width: 109px;
  }

  > :nth-child(2) {
    width: 94px;
  }
}
